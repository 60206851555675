<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="6">
        <v-card outlined>
          <QuemAtende :cliente_id="cliente_id" />
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card outlined>
          <ClienteServicosPrestados :cliente_id="cliente_id" />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Configuracoes",

  props: {
    cliente_id: {
      type: [Number, String],
      required: true,
    },
  },

  components: {
    QuemAtende: () => import("./QuemAtende.vue"),
    ClienteServicosPrestados: () => import("./ClienteServicosPrestados.vue"),
  },
};
</script>

<style lang="scss"></style>
